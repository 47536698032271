import React from 'react'

import {graphql} from "gatsby";
import Helmet from 'react-helmet'
import rehypeReact from 'rehype-react'
import styled from 'styled-components'

import Layout from '../components/layout'
import {Container, Section }  from "../components/containers/Layout";
import {H1, H2, H3, H4, Paragraph} from '../components/base/Typography'


const SectionLessPadding = styled(Section) `
  padding: 0;
  margin-left: 1em;
  margin-right: 1em;    
  margin-bottom: 3em;
  @media (max-width: 600px) {
    padding: 0;
    margin-left: .5em;
    margin-right: .5em;
  }  
`

const Post = styled.div`
  & pre {
    overflow: auto;
    max-width 700px; 
  }
  
  @media (max-width: 750px) {
    & pre {
      max-width: 90vw;
    }
  }
  
  & p, & li {
    font-size: 20px;
    line-height: 1.5;
    font-weight: 400;
    font-style: normal;
  }
  
  & p > code, & li > code {
    background-color: #e2e2e2;
    font-size 17px;
  }
  
  & p + p, & ol + p, & ul + p {
    margin-top: 1.5em;
  }
`
const PostH1 = styled(H1)`
  margin-bottom: 1em;
`
const PostH2 = styled(H2) `
  margin-bottom: .5em;
  margin-top: 1.5em;
`

const PostH3 = styled(H3)`
  margin-bottom: .5em;
  margin-top: 1.5em;
`

const PostH4 = styled(H4)`
  margin-bottom: .5em;
  margin-top: 1.5em;
`


const PostParagraph = styled(Paragraph)`
  color: #000000;
  max-width: unset; 
  margin-top: .5em;
  margin-bottom: .5em;  
`

const OrderedList = styled.ol`
  list-style: decimal;
  margin: .5em 2em 0 2em;

  & li:last-of-type {
    padding-bottom: 0;
  }    
`

const UnorderedList = styled.ul`
  list-style: none;
  margin: .5em 2em 0 2em;
  position: relative;
  
  li::before {
    content: '\\2022'; 
    position: absolute;
    left: 0em;
    font-size: 16px;
  }
  
  
  & li:last-of-type {
    padding-bottom: 0;
  }
`

const ListItem = styled.li`
  margin-left: 1em;
  padding-bottom: 0.5em;
`

const A = styled.a`
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  color: #106ba3;
  text-decoration: underline;
`

const renderAst = new rehypeReact({
  createElement: React.createElement,
  components: {
    h1: PostH1,
    h2: PostH2,
    h3: PostH3,
    h4: PostH4,
    p: PostParagraph,
    ol: OrderedList,
    ul: UnorderedList,
    li: ListItem,
    a: A

  }
}).Compiler

export default ({ data }) => {
  const doc = data.markdownRemark
  return (
    <Layout>
      <Helmet title={doc.frontmatter.title}/>
      <SectionLessPadding>
        <Container style={{maxWidth: 700}}>
          {/*<Post dangerouslySetInnerHTML={{__html: doc.html}}/>*/}
          <Post>{renderAst(doc.htmlAst)}</Post>
        </Container>
      </SectionLessPadding>

    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      htmlAst
      frontmatter {
        title
      }
    }
  }
`